import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '../views/layout/Layout'

export const constantRouterMap = [
  { path: '*',
    name: 'OTHERS',
    redirect: '/404',
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  { path: '/login',
    name: 'LOGIN',
    component: () => import('@/views/login/index'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  { path: '/forgot_password',
    name: 'FORGOT_PASSWORD',
    component: () => import('@/views/login/forgot_password'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  { path: '/verify_token',
    name: 'VERIFY_TOKEN',
    component: () => import('@/views/login/verify_token'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  {
    path: '/verify_social_oauth',
    name: 'VERIFY_SOCIAL_AUTH',
    component: () => import('@/views/login/verify_social_oauth'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  { path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    hidden: true,
    meta: { group: 'DEFAULT' }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    hidden: true,
    meta: { group: 'RECORDS' },
    children: [{
      path: '/dashboard',
      name: 'records',
      component: () => import('@/views/dashboard/index'),
      meta: { group: 'RECORDS' }
    }]
  },
  // Records
  // Hide Minutes
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/minutes',
  //   meta: { group: 'RECORDSADMIN' },
  //   children: [
  //     {
  //       props: { role_type: 'records' },
  //       path: 'minutes',
  //       component: () => import('@/views/records/minutes/index'),
  //       name: 'MINUTES',
  //       meta: { title: 'Minutes', group: 'RECORDSADMIN', icon: 'history' }
  //     }
  //   ]
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/resolutions',
    meta: { group: 'RECORDSADMIN' },
    children: [
      {
        // props: { role_type: 'records' },
        path: 'resolutions',
        component: () => import('@/views/records/resolutions/index'),
        name: 'RESOLUTIONS',
        meta: { title: 'Resolutions', group: 'RECORDSADMIN', icon: 'vote-yea' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/annual_reports',
    meta: { group: 'RECORDSADMIN' },
    children: [
      {
        props: { role_type: 'records' },
        path: 'annual_reports',
        component: () => import('@/views/records/annual_reports/index'),
        name: 'ANNUALREPORTS',
        meta: { title: 'Annual Reports', group: 'RECORDSADMIN', icon: 'book-open' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/memorandum',
    meta: { group: 'RECORDSADMIN' },
    children: [
      {
        props: { memo_type: 'Memorandum', role_type: 'records' },
        path: 'memorandum',
        component: () => import('@/views/records/memo/index'),
        name: 'MEMORANDUM',
        meta: { title: 'Memorandum', group: 'RECORDSADMIN', icon: 'file-alt' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/memorandum_circular',
    meta: { group: 'RECORDSADMIN' },
    children: [
      {
        props: { memo_type: 'Memorandum Circular', role_type: 'records' },
        path: 'memorandum_circular',
        component: () => import('@/views/records/memo/index'),
        name: 'MEMORANDUMCIRCULAR',
        meta: { title: 'Memorandum Circular', group: 'RECORDSADMIN', icon: 'file-alt' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/ipcr',
    meta: { group: 'RECORDSADMIN' },
    children: [
      {
        path: 'ipcr',
        component: () => import('@/views/records/ipcr/index'),
        name: 'IPCR',
        meta: { title: 'IPCR', group: 'RECORDSADMIN', icon: 'user-alt' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/opcr',
    meta: { group: 'RECORDSADMIN' },
    children: [
      {
        path: 'opcr',
        component: () => import('@/views/records/opcr/index'),
        name: 'OPCR',
        meta: { title: 'OPCR', group: 'RECORDSADMIN', icon: 'building' }
      }
    ]
  },
  {
    path: '/files_201',
    component: Layout,
    redirect: '/files_201/academic',
    name: 'FILES201',
    meta: { title: '201 Files', group: 'RECORDSADMIN', icon: 'folder' },
    children: [
      {
        props: { type: 'Faculty' },
        path: 'academic',
        component: () => import('@/views/records/files_201/content/index'),
        name: 'FILES201ACADEMIC',
        meta: { title: 'Academic', group: 'RECORDSADMIN', icon: 'users' }
      },
      {
        props: { type: 'Staff' },
        path: 'administrative',
        component: () => import('@/views/records/files_201/content/index'),
        name: 'FILES201ADMINISTRATIVE',
        meta: { title: 'Administrative', group: 'RECORDSADMIN', icon: 'users' }
      },
      {
        path: 'inventory',
        component: () => import('@/views/records/files_201/content/inventory'),
        name: 'FILES201INVENTORY',
        meta: { title: 'Inventory', group: 'RECORDSADMIN', icon: 'copy' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/ct',
    meta: { group: 'RECORDSADMIN' },
    children: [
      {
        path: 'ct',
        component: () => import('@/views/records/ct/index'),
        name: 'CT',
        meta: { title: 'Certificate of Title', group: 'RECORDSADMIN', icon: 'certificate' }
      }
    ]
  },
  // End Records
  // ===================================================================================================================
  // Scholarship Secretary
  {
    path: '/',
    component: Layout,
    redirect: '/sec_sch_minutes',
    meta: { group: 'RECORDSSCH' },
    children: [
      {
        props: { role_type: 'records_sch' },
        path: 'sec_sch_minutes',
        component: () => import('@/views/records/minutes/index'),
        name: 'SECSCHMINUTES',
        meta: { title: 'Minutes', group: 'RECORDSSCH', icon: 'history' }
      }
    ]
  },
  // End Scholarship Secretary
  // Personal Board Secretary
  {
    path: '/',
    component: Layout,
    redirect: '/sec_pb_minutes',
    meta: { group: 'RECORDSPB' },
    children: [
      {
        props: { role_type: 'records_pb' },
        path: 'sec_pb_minutes',
        component: () => import('@/views/records/minutes/index'),
        name: 'SECPBMINUTES',
        meta: { title: 'Minutes', group: 'RECORDSPB', icon: 'history' }
      }
    ]
  },
  // End Personal Board Secretary
  // OUS Secretary
  {
    path: '/',
    component: Layout,
    redirect: '/sec_ous_minutes',
    meta: { group: 'RECORDSOUS' },
    children: [
      {
        props: { role_type: 'records_ous' },
        path: 'sec_ous_minutes',
        component: () => import('@/views/records/minutes/index'),
        name: 'SECOUSMINUTES',
        meta: { title: 'Minutes', group: 'RECORDSOUS', icon: 'history' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/sec_ous_resolutions',
    meta: { group: 'RECORDSOUS' },
    children: [
      {
        // props: { reso_type: 'records_ous' },
        path: 'sec_ous_resolutions',
        component: () => import('@/views/records/resolutions/index'),
        name: 'SECOUSRESOLUTIONS',
        meta: { title: 'Resolutions', group: 'RECORDSOUS', icon: 'vote-yea' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/ous_annual_reports',
    meta: { group: 'RECORDSOUS' },
    children: [
      {
        props: { role_type: 'records_ous' },
        path: 'ous_annual_reports',
        component: () => import('@/views/records/annual_reports/index'),
        name: 'OUSANNUALREPORTS',
        meta: { title: 'Annual Reports', group: 'RECORDSOUS', icon: 'book-open' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/ous_memorandum',
    meta: { group: 'RECORDSOUS' },
    children: [
      {
        props: { memo_type: 'Memorandum', role_type: 'records_ous' },
        path: 'ous_memorandum',
        component: () => import('@/views/records/memo/index'),
        name: 'OUSMEMORANDUM',
        meta: { title: 'Memorandum', group: 'RECORDSOUS', icon: 'file-alt' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/ous_memorandum_circular',
    meta: { group: 'RECORDSOUS' },
    children: [
      {
        props: { memo_type: 'Memorandum Circular', role_type: 'records_ous' },
        path: 'ous_memorandum_circular',
        component: () => import('@/views/records/memo/index'),
        name: 'OUSMEMORANDUMCIRCULAR',
        meta: { title: 'Memorandum Circular', group: 'RECORDSOUS', icon: 'file-alt' }
      }
    ]
  },
  // End OUS Secretary
  // Memo Secretary
  {
    path: '/',
    component: Layout,
    redirect: '/sec_memorandum',
    meta: { group: 'RECORDSMEMO' },
    children: [
      {
        props: { memo_type: 'Memorandum' },
        path: 'sec_memorandum',
        component: () => import('@/views/records/memo/index'),
        name: 'SECMEMORANDUM',
        meta: { title: 'Memorandum', group: 'RECORDSMEMO', icon: 'file-alt' }
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/sec_memorandum_circular',
    meta: { group: 'RECORDSMEMO' },
    children: [
      {
        props: { memo_type: 'Memorandum Circular' },
        path: 'sec_memorandum_circular',
        component: () => import('@/views/records/memo/index'),
        name: 'SECMEMORANDUMCIRCULAR',
        meta: { title: 'Memorandum Circular', group: 'RECORDSMEMO', icon: 'file-alt' }
      }
    ]
  }
  // End Memo Secretary
]

export default new Router({
  mode: 'history',
  hash: false,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
