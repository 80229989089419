<template>
  <el-select
    v-model="head"
    :remote-method="searchPosition"
    :remote="true"
    :loading="loading"
    :size="button_size"
    clearable
    filterable
    placeholder="Not assign yet"
    @change="updateParent">
    <el-option v-for="personnel in personnels" :key="personnel.id" :value="personnel.id" :label="personnel.fullname">{{ personnel.fullname }}</el-option>
  </el-select>
</template>

<script>
import { Personnel } from '@/api/personnel'

export default {
  name: 'SelectPersonnel',
  props: {
    post: {
      type: Object,
      default: null
    },
    button_size: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      defaultHead: {
        id: null
      },
      personnels: [this.defaultHead, this.post],
      head: this.post
    }
  },
  watch: {
    post: function(item) {
      this.personnels = [this.defaultHead]
      this.head = this.defaultHead
      if (item) {
        this.personnels = [this.defaultHead, item]
        this.head = item
      }
    }
  },
  created() {
    this.personnels = this.personnels.filter(function(element) {
      return element !== undefined
    })

    if (this.post) {
      this.personnels = [this.defaultHead, this.post]
      this.head = this.post
    } else {
      this.head = this.defaultHead
      this.personnels = [this.defaultHead]
    }
  },
  methods: {
    updateParent(id) {
      this.head_id = id
      this.$emit('setPersonnel', id)
    },
    searchPosition(query) {
      this.loading = true
      setTimeout(() => {
        Personnel({ term: query }, 'search').then(res => {
          this.personnels = res.data.personnel
          this.loading = false
        }).catch(() => {})
      }, 100)
    },
    getPositions() {
      Personnel({ per_page: 5 }, 'get').then(res => {
        this.personnels = res.data.personnel
      }).catch(() => {})
    }
  }
}
</script>
